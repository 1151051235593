import React from "react"
import Layout from "@components/kaigai-fx/layout";
import 'tw-elements';

// 関数呼び出し
import { TermComponent } from "@components/kaigai-fx/term-component.js";

const TermPageA = () => {
  // 設定項目 ================================================================================================
  const myLine = 'あ行'
  const myID = 'a_'
  const pathName = 'term';
  const termItem = [ // 改行するときは<br>を追加
    {
      Title:'預り評価残高',
      Content:'口座資産に評価損益を足したお客様の実質的なご資産'
    },
    {
      Title:'ASK（アスク）',
      Content:'為替取引において売り手側が提示する売値のこと<br>Ask rate(アスク・レート)ともいう'
    },
    {
      Title:'IFO（アイエフオー）注文',
      Content:'買いと売り、もしくは売りと買いをセットにして同時に発注するIFD注文に、<br>セットで発注したどちらかの注文が約定した場合はもう一方が自動的にキャンセルされるOCO注文が加わった注文方法です。'
    },
    {
      Title:'IFD（アイエフディー）注文',
      Content:'新規注文と決済注文を同時に発注する注文方法です。<br>例えば、〇〇円になったら新規で買い、〇〇円になったら決済をすると設定すると<br>新規注文から決済注文まですべて自動で行ってくれます。'
    },
    {
      Title:'RVI（アールブイアイ）',
      Content:'Relative Volatility TermPageAの略で、テクニカル分析のひとつです。 <br>一定期間でどのくらい値上がり・値下がりしたか、「絶対的な価格の変化」を視覚的に判断することができます。<br>RVIラインとシグナルラインと呼ばれる2本のラインで示されます。<br>RVIラインがシグナルラインを下から上に抜けた場合は買い、シグナルラインがRVIラインを上から下に抜けた場合は売り、<br>のように売買タイミングを見るために使われます。'
    },
    {
      Title:'RSI（アールエスアイ）',
      Content:'Relative Strength TermPageAの略で、テクニカル分析のひとつです。 <br>買われすぎか、売られすぎかを判断するための指標です。<br>数値は0～100で表され、一般的に70～80％以上で買われすぎ、20～30％以下で売られすぎと判断されます。'
    },
    {
      Title:'EA（イーエー）',
      Content:'Expert Adviserの略でFXの自動売買プログラムのことです。'
    },
    {
      Title:'売り決済',
      Content:'買いポジションを保有しているとき、反対売買である「売り」をして決済し、取引を終了させること。<br>決済注文を出して利益や損失を確定させます。'
    },
    {
      Title:'売りポジション',
      Content:'売り越している状態のこと（ショートポジションとも言う）<br>売りポジションを保有した時から価格が下落したときに収益が上がり、<br>価格が上昇したときには損失が発生します。'
    },
    {
      Title:'FOMC（エフオーエムシー）',
      Content:'Federal Open Market Committee（連邦公開市場委員会）の略です。<br>米国の金融政策を決定する会合のことです。'
    },
    {
      Title:'円高',
      Content:'円1単位で交換できる他通貨の単位数が相対的に多い状態のことです。<br>（円の価値が相対的に上がること）'
    },
    {
      Title:'円安',
      Content:'円1単位で交換できる他通貨の単位数が相対的に少ない状態のことです。<br>（円の価値が相対的に下がること）'
    },
    {
      Title:'OCO注文',
      Content:'「One Cancels the Other」を略です。<br>2種類の注文を一度に出し、どちらかの注文が約定した場合はもう一方が自動的にキャンセルされる注文方法です。'
    },
    {
      Title:'終値',
      Content:'1日の相場取引の最後についた価格のことです。<br>終値には、午前と午後があり、「○日の終値」という場合は、午後の終値を指します。'
    }
  ]
  // =================================================================================================================================
  // term-component.jsにて詳細設定
  let termPageContent = TermComponent( myLine, myID, termItem, pathName ); 
  
  return (
  <>
    <Layout>
      {termPageContent}
    </Layout>
  </>
  )
}
export default TermPageA